import requests from 'axios'
import config from './config'

const URL = `${config.URL}/notification`

export async function list () {
  const result = await requests.get(`${URL}/list/1`, config.configWithToken())
  return result.data
}

export default {
  list
}
