import React, { Component } from 'react'
import './Us.css'

class Us extends Component {
  render () {
    return (
      <section className='section-iggy'>
        <div className='container fuild-small p-0'>
          <div className='d-md-flex col-12 p-0'>
            <div className='square square-img col-12 col-md-7 order-md-2'>
              <div className='circle circle-big position-absolute' />
            </div>
            <div className='square square-text text-center text-md-left p-5 col-12 col-md-5 order-md-1'>
              <h2 className='text-uppercase mt-3 mb-5'>Iggy</h2>
              <p>
                <b>Conecta padres e hijos deportistas con reclutadores y clubes deportivos</b>
                <br /><br />
                Todo <b>padre</b> desea lo mejor para su hijo. Con IGGY tendrás más oportunidades de que descubran el talento de tu hijo para el deporte. Tu puedes ser su representante, haz tu parte y nosotros la nuestra.
                <b>Haz que lo vean.</b>
                <br /><br />
                Como <b>Club</b> tendrá acceso a jugadores de divisiones menores de Latinoamérica. Entérese primero, contáctelos y haga pruebas. Descubra el talento deportivo de una manera más rápida y eficiente.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Us
