import React, { Component } from 'react'
import Logo from '../../../../static/img/logo_header.png'

class Header extends Component {
  render () {
    return (
      <div>
        <div className='d-none d-md-block d-xl-block'>
          <div className='navbar navbar-expand-md flex-column justify-content-left d-none d-md-flex d-xl-flex'>
            <div className='container'>
              <a className='navbar-brand mt-5' href='/'>
                <img alt='Iggyball' src={Logo} />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
