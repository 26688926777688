import requests from 'axios'
import config from './config'

const URL = `${config.URL}/auth`

export async function resetPassword (userName) {
  const result = await requests.post(`${URL}/reset/password`, {userName})
  return result.data
}

export async function validateToken (token) {
  const result = await requests.get(`${URL}/reset/password/verify/${token}`)
  return result.data
}

export async function changePassword (token, password) {
  const result = await requests.post(`${URL}/reset/password/change`, {token, password})
  return result.data
}

export default {
  resetPassword,
  validateToken,
  changePassword
}
