import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../static/img/logo.png'
import logoMobile from '../../static/img/logo_mobile.png'

class Header extends Component {
  render () {
    return (
      <header className='main-content vh-100'>
        <div className='container py-4'>
          <div className='d-flex align-items-center text-center text-md-left'>
            <div className='d-flex flex-column col-md-8'>
              <Link to='/' className='navbar-brand mb-0'>
                <img className='d-none d-md-block' src={logo} alt='Iggyball' />
                <img className='d-md-none' src={logoMobile} alt='Iggyball' />
              </Link>
              <h1 className='display-5 text-uppercase' style={{color: 'white'}}>
                <span>Que el deporte sea la llave para el</span>
                <span className='bold'> futuro de tus hijos</span>
              </h1>
              <p className='mt-4 mt-md-3 mb-5 mb-md-4 main-description text-white'>
                Conectamos padres e hijos deportistas con reclutadores y clubes deportivos
              </p>
              <p className='lead main-action pt-3'>
                <a className='btn btn-primary-c text-uppercase' role='button' href='/login'>Ingresa con tu teléfono o tu correo</a>
              </p>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
