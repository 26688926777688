import requests from 'axios'
import config from './config'

const URL = `${config.URL}/landing`

export async function detail (url) {
  const result = await requests.post(`${URL}/detail`, {url})
  return result.data
}

export default {
  detail
}
