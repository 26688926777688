import React, { Component } from 'react'
import { Form, FormGroup, Label, Input, Alert } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import API from '../../../api/index'
import Logo from '../../../static/img/logo-green.png'
import './Reset.css'

class Reset extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      username: '',
      error: false,
      messageError: '',
      messageSuccess: '',
      redirect: false
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  updateInfo (key, value) {
    this.setState({
      [key]: value
    })
  }

  onChange (event) {
    this.updateInfo(event.target.name, event.target.value)
  }

  async onSubmit () {
    if (this.state.username) {
      await API.password.resetPassword(this.state.username)
      this.updateInfo('messageSuccess', 'Se han enviado instrucciones a su correo electrónico')
      this.updateInfo('username', '')
    } else {
      this.updateInfo('error', true)
      this.updateInfo('messageError', 'El correo electrónico es obligatorio')
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to='/feed' />)
    }
    return (
      <div className='section-iggy center-block-login'>
        <div className='container-reset fuild-small'>
          <div className='text-center width-login d-md-flex col-12 p-0'>
            <div className='margin-auto pt-4'>
              <a href='/'>
                <img src={Logo} alt='IggyBall' />
              </a>
              <h5 className='text-uppercase mt-3 font-weight-bold'>Bienvenido</h5>
              <p className='font-weight-light sub-title-login'>¿Olvidaste tu contraseña?</p>
            </div>
          </div>
          <div className='width-form'>
            {this.state.error && (
              <Alert color='danger'>
                {this.state.messageError}
              </Alert>
            )}
            {this.state.messageSuccess && (
              <Alert color='success'>
                {this.state.messageSuccess}
              </Alert>
            )}
            {!this.state.messageSuccess && (
              <Form>
                <FormGroup>
                  <Label for='username' className='font-weight-light sub-title-login'>Ingresa tu correo electrónico</Label>
                  <Input type='email' name='username' id='username' placeholder='Ingrese correo electrónico' className='form-control-login' onChange={this.onChange} value={this.state.username} />
                </FormGroup>
              </Form>
            )}
            <p className='text-center'>
              {!this.state.messageSuccess ? (
                <button className='btn btn-success-a rounded-0' onClick={this.onSubmit}>Restablecer contraseña</button>
              ) : (
                <a href='/login' className='btn btn-success-a rounded-0'>Iniciar sesión</a>
              )}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Reset
