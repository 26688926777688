import React, { Component } from 'react'
import API from '../../api/index'
import './Contact.css'

class Contact extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showForm: true,
      email: '',
      emailError: false,
      emailErrorText: ''
    }
    this.cleanState = this.state
    this.onChange = this.onChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  onChange (event) {
    this.setState({
      'email': event.target.value
    })
  }

  async submit () {
    if (this.state.email !== '') {
      const response = await API.subscription.create(this.state.email)
      if (response.errors) {
        this.setState({
          emailError: true,
          emailErrorText: response.errors.email.message
        })
      } else {
        this.setState(this.cleanState)
        this.setState({showForm: false})
      }
    } else {
      this.setState({
        emailError: true,
        emailErrorText: 'El correo electrónico es obligatorio'
      })
    }
  }

  render () {
    return (
      <section>
        <div className='container fluid-small grey-gradient pt-5 mt-5 pb-5 mb-5'>
          <div className='d-flex flex-column align-items-center text-center pt-5'>
            <div className='mb-2'>
              <h2 className='text-uppercase'>
                ¿Te gustaría conocer<br />  más sobre Iggy?
              </h2>
            </div>
            <div className='mb-1'>
              <p>Dejanos tu email y nos pondremos en contacto</p>
            </div>
            {this.state.emailError && (
              <div className='mb-1 text-danger'>
                {this.state.emailErrorText}
              </div>
            )}
            {this.state.showForm ? (
              <div className='col-12 col-md-6 mt-4 mb-5'>
                <div className='text-uppercase'>
                  <input
                    aria-describedby='basic-addon2'
                    aria-label='email'
                    className='form-control rounded-0 col-12 col-md-8 p-3 mb-2 float-left text-center'
                    name='email'
                    placeholder='nombre@correo.com'
                    onChange={this.onChange}
                  />
                  <button className='btn btn-success-a rounded-0 float-right col-12 col-md-4 p-3' onClick={this.submit} type='submit' >Enviar</button>
                </div>
              </div>
            ) : (
              <div className='mb-1'>
                Gracias por comunicarte con nosotros. A la brevedad nos contactaremos contigo.
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default Contact
