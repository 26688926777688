import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { getItem } from '../../api/utils'
import Logo from '../../static/img/logo_full_white.png'
import LogoHome from '../../static/images/home.png'
import LogoProfile from '../../static/images/profile.png'
import ProfileBlank from '../../static/img/img_perfil_blank.png'

const Header = () => {
  const username = getItem('username')
  const fullName = getItem('full_name')
  const image = getItem('image')
  const [redirect, setRedirect] = React.useState(false)

  async function onClose () {
    window.sessionStorage.clear()
    setRedirect(true)
  }

  if (redirect) {
    return (<Redirect to='/' />)
  }
  
  return (
    <header>
			<div className='container'>
				<div className='header-data'>
					<div className='logo'>
            <Link to='/feed'>
              <img src={Logo} alt='Iggyball' />
            </Link>
					</div>
					<div className='search-bar'>
						<form action='/search'>
							<input type='text' name='fullName' placeholder='Buscar...' />
							<button type='submit'><i className='la la-search'></i></button>
						</form>
					</div>
					<nav>
						<ul>
							<li>
                <Link to='/feed'>
                  <span>
                    <img src={LogoHome} alt='Inicio' />
                  </span>
									Inicio
                </Link>
							</li>
              <li>
                <Link to={`/user/${username}`}>
                  <span>
                    <img src={LogoProfile} alt='Perfil' />
                  </span>
									Perfil
                </Link>
							</li>
						</ul>
					</nav>
					<div className='user-account'>
						<div className='user-info'>
							<img src={image || ProfileBlank} alt={username} />
              <a href='#'>{fullName}</a>
							<i className='la la-sort-down'></i>
						</div>
						<div className='user-account-settingss' id='users'>
							<h3>Configuración</h3>
							<ul className='us-links'>
                <li>
                  <Link to='/user/edit'>Configuración de cuenta</Link>
                </li>
                <li>
                  <Link to='/info/privacy'>Políticas de privacidad</Link>
                </li>
                <li>
                  <Link to='/info/terms'>Términos y condiciones</Link>
                </li>
							</ul>
							<h3 className='tc'>
                <a onClick={() => onClose()}>Cerrar sesión</a>
              </h3>
					  </div>
					</div>
				</div>
			</div>
		</header>
  )
}

export default Header
