import React from 'react'
import { FormGroup, Input, FormFeedback } from 'reactstrap'
import { Redirect, Link } from 'react-router-dom'
import API from '../../../api/index'
import Logo from '../../../static/img/logo-green.png'
import CopyIcon from '../../../static/images/copy-icon.png'
import './Register.css'


const Register = (props) => {
  const [usernameError, setUsernameError] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [firstNameError, setFirstNameError] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastNameError, setLastNameError] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [passwordError, setPasswordError] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [typeUserError, setTypeUserError] = React.useState('')
  const [typeUser, setTypeUser] = React.useState('')
  const [position, setPosition] = React.useState('')
  const [positionError, setPositionError] = React.useState('')
  const [country, setCountry] = React.useState('')
  const [countries, setCountries] = React.useState([])
  const [state, setState] = React.useState('')
  const [states, setStates] = React.useState([])
  const [city, setCity] = React.useState('')
  const [cities, setCities] = React.useState([])
  const [cityError, setCityError] = React.useState('')
  const [redirect, setRedirect] = React.useState(false)

  function capitalizeOnlyFirst (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  function createRow (values) {
    return values.map(value => {
      return <option value={value.id} key={value.id}>{capitalizeOnlyFirst(value.name)}</option>
    })
  }

  async function getCountries () {
    const response = await API.country.list()
    const countriesRow = createRow(response)
    setCountries(countriesRow)
  }

  React.useEffect(() => {
    getCountries()
  }, [])

  async function getStates (idCountry) {
    const response = await API.state.list(idCountry)
    setStates(createRow(response))
  }

  async function getCities (idState) {
    const response = await API.city.list(idState)
    setCities(createRow(response))
  }

  async function onSubmit () {
    if (city) {
      let send = true
      const data = {
        first_name: firstName,
        last_name: lastName,
        type_user: typeUser,
        userName: username,
        password: password,
        city: city
      }
      if (typeUser === 'Jugador') {
        if (position !== '') {
          data['position'] = position
        } else {
          send = false
          setPositionError('La posición es obligatoria')
        }
      }
      if (send) {
        const response = await API.account.create(data)
        if (response.ok) {
          API.auth.login(username, password).then(() => {
            setRedirect(true)
          })
        } else {
          if (response.message) {
            setUsernameError('El usuario ya existe')
          }
          if (response.errors) {
            const errors = response.errors
            if (errors.type_user) {
              setTypeUserError(errors.type_user[0])
            }
            if (errors.first_name) {
              setFirstNameError(errors.first_name[0])
            }
            if (errors.last_name) {
              setLastNameError(errors.last_name[0])
            }
            if (errors.userName) {
              setUsernameError(errors.userName[0])
            }
            if (errors.password) {
              setPasswordError(errors.password[0])
            }
          }
        }
      }
    } else {
      setCityError('La ciudad es obligatoria')
    }
  }

  if (redirect) {
    return (<Redirect to='/user/edit' />)
  }

  return (
    <div className='sign-in'>
      <div className='wrapper' style={{backgroundColor: '#00a96e'}}>
        <div className='sign-in-page'>
			    <div className='signin-popup'>
				    <div className='signin-pop'>
					    <div className='row'>
						    <div className='col-lg-6'>
							    <div className='cmp-info'>
								    <div className='cm-logo'>
                      <Link to='/'>
                        <img src={Logo} alt='Iggyball' />
                      </Link>
								    </div>
                    <div className='sign_in_sec current' id='tab-2'>
									    <h3>Regístrate</h3>
									    <form onClick={(event) => event.preventDefault()}>
										    <div className='row'>
											    <div className='col-lg-12 no-pdd'>
                            <FormGroup>
                              <Input
                                invalid={!!usernameError}
                                type='text'
                                placeholder='Celular o correo electrónico'
                                className='form-control-login'
                                onChange={(event) => setUsername(event.target.value)}
                                value={username}
                              />
                              {usernameError && (
                                <FormFeedback>{usernameError}</FormFeedback>
                              )}
                            </FormGroup>
											    </div>
                          <div className='col-lg-12 no-pdd'>
                            <FormGroup>
                              <Input
                                invalid={!!firstNameError}
                                type='text'
                                placeholder='Nombre'
                                className='form-control-login'
                                onChange={(event) => setFirstName(event.target.value)}
                                value={firstName}
                              />
                              {firstNameError && (
                                <FormFeedback>{firstNameError}</FormFeedback>
                              )}
                            </FormGroup>
											    </div>
                          <div className='col-lg-12 no-pdd'>
                            <FormGroup>
                              <Input
                                invalid={!!lastNameError}
                                type='text'
                                placeholder='Apellidos'
                                className='form-control-login'
                                onChange={(event) => setLastName(event.target.value)}
                                value={lastName}
                              />
                              {lastNameError && (
                                <FormFeedback>{lastNameError}</FormFeedback>
                              )}
                            </FormGroup>
											    </div>
											    <div className='col-lg-12 no-pdd'>
                            <FormGroup>
                              <Input
                                invalid={!!lastNameError}
                                type='password'
                                placeholder='Contraseña'
                                className='form-control-login'
                                onChange={(event) => setPassword(event.target.value)}
                                value={password}
                              />
                              {passwordError && (
                                <FormFeedback>{passwordError}</FormFeedback>
                              )}
                            </FormGroup>
											    </div>
										    </div>
									    </form>
								    </div>
							    </div>
						    </div>
						    <div className='col-lg-6'>
							    <div className='login-sec'>
								    <ul className='sign-control'>
									    <li data-tab='tab-1'>
                        <Link to='/login'>Iniciar sesión</Link>
                      </li>
									    <li data-tab='tab-2' className='current'>
                        <Link to='/register'>Regístrate</Link>
                      </li>
								    </ul>
								    <div className='sign_in_sec current'>
                      <h3 className='text-white'>Regístrate</h3>
									    <form onClick={(event) => event.preventDefault()}>
										    <div className='row'>
											    <div className='col-lg-12 no-pdd'>
                            <FormGroup>
                              <Input
                                invalid={!!typeUserError}
                                type='select'
                                onChange={(event) => setTypeUser(event.target.value)}
                                className='form-control-login'
                              >
                                <option value=''>Qué eres?</option>
                                <option value='Jugador'>Soy un Jugador</option>
                                <option value='Club'>Soy un Club</option>
                                <option value='Representante'>Tengo jugadores (Representante)</option>
                                <option value='Reclutador'>Busco jugadores (Reclutador)</option>
                              </Input>
                              {typeUserError && (
                                <FormFeedback>{typeUserError}</FormFeedback>
                              )}
                            </FormGroup>
											    </div>
                          <div className='col-lg-12 no-pdd'>
                            <FormGroup>
                              <Input
                                type='select'
                                onChange={(event) => {
                                  setCountry(event.target.value)
                                  setState('')
                                  setCity('')
                                  setCities('')
                                  setCityError('')
                                  getStates(event.target.value)
                                }}
                                className='form-control-login'
                              >
                                <option value=''>Seleccione un país</option>
                                {countries}
                              </Input>
                            </FormGroup>
											    </div>
                          <div className='col-lg-12 no-pdd'>
                            <FormGroup>
                              <Input
                                type='select'
                                onChange={(event) => {
                                  setState(event.target.value)
                                  getCities(event.target.value)
                                }}
                                className='form-control-login'
                              >
                                <option value=''>Estado / Departamento</option>
                                {states}
                              </Input>
                            </FormGroup>
											    </div>
                          <div className='col-lg-12 no-pdd'>
                            <FormGroup>
                              <Input
                                invalid={!!cityError}
                                type='select'
                                onChange={(event) => setCity(event.target.value)}
                                className='form-control-login'
                              >
                                <option value=''>Seleccione una ciudad</option>
                                {cities}
                              </Input>
                              {cityError && (
                                <FormFeedback>{cityError}</FormFeedback>
                              )}
                            </FormGroup>
											    </div>
                          {typeUser === 'Jugador' && (
                            <div className='col-lg-12 no-pdd'>
                              <FormGroup>
                                <Input
                                  invalid={!!positionError}
                                  type='select'
                                  onChange={(event) => setPosition(event.target.value)}
                                  className='form-control-login'
                                >
                                  <option value=''>Seleccione un puesto / posición</option>
                                  <option value='Arquero'>Arquero</option>
                                  <option value='Defensor'>Defensor</option>
                                  <option value='Mediocampista'>Mediocampista</option>
                                  <option value='Delantero'>Delantero</option>
                                </Input>
                                {positionError && (
                                  <FormFeedback>{positionError}</FormFeedback>
                                )}
                              </FormGroup>
                            </div>
                          )}
											    <div className='col-lg-12 no-pdd'>
												    <button type='submit' onClick={(event) => onSubmit(event)}>Registrarte</button>
											    </div>
										    </div>
									    </form>
								    </div>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
			    <div className='footy-sec'>
				    <div className='container'>
					    <ul>
						    <li>
                  <Link to='/info/privacy'>Políticas de privacidad</Link>
                </li>
						    <li>
                  <Link to='/info/terms'>Términos y condiciones</Link>
                </li>
					    </ul>
					    <p><img src={CopyIcon} alt='Copyright 2018' />Copyright 2018 - Iggyball</p>
				    </div>
			    </div>
		    </div>
      </div>
    </div>
  )
}

export default Register
