import requests from 'axios'
import config from './config'

const URL = `${config.URL}/subscription`

export async function create (email) {
  const result = await requests.post(URL, {email})
  return result.data
}

export default {
  create
}
