import PortadaBackground from './images/resources/portada.png'
import PostLogo from './images/post.png'
import InfoLogo from './images/ic2.png'
import ProfileBlank from './img/img_perfil_blank.png'

export const images = {
  PortadaBackground,
  PostLogo,
  InfoLogo,
  ProfileBlank
}
