import React from 'react'
import { Redirect } from 'react-router-dom'
import Header from '../../Header'
import API from '../../../../api/index'

const Common = (props) => {
  const user = props.user
  const [firstName, setFirstName] = React.useState(user.first_name)
  const [lastName, setLastName] = React.useState(user.last_name)
  const [dni, setDni] = React.useState(user.dni)
  const [gender, setGender] = React.useState(user.gender)
  const [email, setEmail] = React.useState(user.email)
  const [cellphone, setCellphone] = React.useState(user.cellphone)
  const [birthdate, setBirthdate] = React.useState(user.birthdate)
  const [redirect, setRedirect] = React.useState(false)
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [country, setCountry] = React.useState('')
  const [state, setState] = React.useState('')
  const [city, setCity] = React.useState('')
  const [biography, setBiography] = React.useState(user.biography)
  const [privacy, setPrivacy] = React.useState(user.privacy)

  React.useEffect(() => {
    getCountries()
    if (props.user.city) {
      getCity()
    }
  }, [])

  async function getCity () {
    let response = await API.city.detail(props.user.city)
    response = response.record
    if (response.city) {
      setCountry(response.country.id)
      getStates(response.country.id)
      setState(response.state.id)
      getCities(response.state.id)
      setCity(response.city.id)
    }
  }

  function capitalizeOnlyFirst (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  function createRow (values) {
    const row = []
    values.map(value => {
      const name = capitalizeOnlyFirst(value.name)
      return row.push(<option value={value.id} key={value.id}>{name}</option>)
    })
    return row
  }

  async function getCountries () {
    const response = await API.country.list()
    const countriesRow = createRow(response)
    setCountries(countriesRow)
  }

  async function getStates (idCountry) {
    const response = await API.state.list(idCountry)
    const stateRow = createRow(response)
    setStates(stateRow)
  }

  async function getCities (idState) {
    const response = await API.city.list(idState)
    const citiesRow = createRow(response)
    setCities(citiesRow)
  }

  async function onSubmit () {
    await API.account.updateCommon({
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      email: email,
      cellphone: cellphone,
      biography: biography,
      birthdate: birthdate,
      city: city,
      dni: dni
    })
    setRedirect(true)
  }

  if (redirect) {
    return (
      <Redirect to='/feed' />
    )
  }

  return (
    <>
      <Header select='profile' />
      <section className='profile-account-setting'>
        <div className='container'>
          <div className='account-tabs-setting'>
            <div className='row'>
              <div className='col-lg-3'>
                <div className='acc-leftbar'>
                  <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                    <a className='nav-item nav-link active' id='nav-general-information' data-toggle='tab' href='#general-information' role='tab' aria-controls='general-information' aria-selected='true'><i className='fa fa-user-secret' />Información general</a>
                    <a className='nav-item nav-link' id='place' data-toggle='tab' href='#place-live' role='tab' aria-controls='place-live' aria-selected='false'><i className='fa fa-address-card' />Lugar dónde vives</a>
                  </div>
                </div>
              </div>
              <div className='col-lg-9'>
                <div className='tab-content' id='nav-tabContent'>
                  <div className='tab-pane fade show active' id='general-information' role='tabpanel' aria-labelledby='nav-general-information'>
                    <div className='acc-setting'>
                      <h3>Información general</h3>
                      <form onSubmit={(event) => event.preventDefault()}>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Nombre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Nombre' value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                                <i className='fa fa-user' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Apellido</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Apellido' value={lastName} onChange={(event) => setLastName(event.target.value)} />
                                <i className='fa fa-user' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Género</h5>
                              <div className='cpp-fiel'>
                                <select className='form-control' style={{ '-webkit-appearance': 'menulist-button' }} value={gender} onChange={(event) => setGender(event.target.value)}>
                                  <option value='masculino'>Masculino</option>
                                  <option value='femenino'>Femenino</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Tipo de perfil</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={user.type_user} disabled />
                                <i className='fa fa-male' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Correo electrónico</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Correo electrónico' value={email} onChange={(event) => setEmail(event.target.value)} />
                                <i className='fa fa-envelope' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Celular</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Celular' value={cellphone} onChange={(event) => setCellphone(event.target.value)} />
                                <i className='fa fa-mobile' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Fecha de nacimiento</h5>
                              <div className='cpp-fiel'>
                                <input type='date' value={birthdate} onChange={(event) => setBirthdate(event.target.value)} />
                                <i className='fa fa-birthday-cake' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>DNI</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='DNI' value={dni} onChange={(event) => setDni(event.target.value)} />
                                <i className='fa fa-drivers-license-o' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Cuenta privada</h5>
                              <div className='custom-control custom-checkbox'>
                                <input type='checkbox' className='custom-control-input' id='privacy' checked={privacy} onChange={() => setPrivacy(!privacy)} />
                                <label className='custom-control-label' for='privacy'>Si tu cuenta es privada, solo las personas que apruebes podrán ver tus fotos y videos en Iggyball. Esto no afectará a tus seguidores actuales.</label>
                              </div>
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='cp-field'>
                              <h5>Biografiía</h5>
                              <textarea value={biography} onChange={(event) => setBiography(event.target.value)} rows={3} />
                            </div>
                          </div>
                        </div>
                        <div className='save-stngs pd2'>
                          <ul>
                            <li><button type='submit' onClick={() => onSubmit()}>Guardar cambios</button></li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='tab-pane fade' id='place-live' role='tabpanel' aria-labelledby='place'>
                    <div className='acc-setting'>
                      <h3>Lugar dónde vives</h3>
                      <form onSubmit={(event) => event.preventDefault()}>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>País</h5>
                              <div className='cpp-fiel'>
                                <select
                                  className='form-control'
                                  style={{ '-webkit-appearance': 'menulist-button' }}
                                  value={country}
                                  onChange={(event) => {
                                    setCountry(event.target.value)
                                    getStates(event.target.value)
                                  }}
                                >
                                  <option value=''>Seleccione un país</option>
                                  {countries}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Estado / Departamento</h5>
                              <div className='cpp-fiel'>
                                <select
                                  className='form-control'
                                  style={{ '-webkit-appearance': 'menulist-button' }}
                                  value={state}
                                  onChange={(event) => {
                                    setState(event.target.value)
                                    getCities(event.target.value)
                                  }}
                                >
                                  <option value=''>Seleccione un estado / departamento</option>
                                  {states}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Ciudad</h5>
                              <div className='cpp-fiel'>
                                <select className='form-control' style={{ '-webkit-appearance': 'menulist-button' }} value={city} onChange={(event) => setCity(event.target.value)}>
                                  <option value=''>Seleccione una ciudad</option>
                                  {cities}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='save-stngs pd2'>
                          <ul>
                            <li><button type='submit' onClick={() => onSubmit()}>Guardar cambios</button></li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Common
