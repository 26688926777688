import React from 'react'
import { Redirect } from 'react-router-dom'
import Header from '../Header'
import API from '../../../api/index'
import PlayerUpdate from './update/Player'
import ClubUpdate from './update/Club'
import CommonUpdate from './update/Common'

const UpdateProfile = (props) => {
  const [user, setUser] = React.useState({})
  const [typeProfile, setTypeProfile] = React.useState('')
  const [redirect, setRedirect] = React.useState(false)

  async function getProfile () {
    const response = await API.account.detail()
    if (response.ok) {
      setUser(response)
      if (typeof response.type_user === 'object') {
        setTypeProfile(response.type_user[0])
      } else {
        setTypeProfile(response.type_user)
      }
    } else {
      setRedirect(true)
    }
  }

  React.useEffect(() => {
    window.document.title = 'Configuración general de la cuenta'
    getProfile()
  }, [])

  if (redirect) {
    return (
      <Redirect to='/login' />
    )
  }

  if (typeProfile === 'Jugador') {
    return (
      <PlayerUpdate user={user} />
    )
  }

  if (typeProfile === 'Club') {
    return (
      <ClubUpdate user={user} />
    )
  }

  if (typeProfile === 'Representante' || typeProfile === 'Reclutador') {
    return (
      <CommonUpdate user={user} />
    )
  }

  return (
    <div>
      <Header select='profile' />
    </div>
  )
}

export default UpdateProfile
