import React, { Component } from 'react'
import './SecondAction.css'

class SecondAction extends Component {
  render () {
    return (
      <section className='secondary-action'>
        <div className='container fluid-small grey-gradient'>
          <div className='d-flex flex-column align-items-center text-center pt-5'>
            <div className='mb-3'>
              <h2 className='text-uppercase'>CONECTAMOS DEPORTISTAS Y CLUBES</h2>
            </div>
            <div className='mb-1 sub-heading-big text-uppercase'>
              <p>EL TIEMPO ES VALIOSO ÚNETE Y HAZ QUE TE VEAN</p>
            </div>
            <div className='col-12 mt-4 mb-5 p-0'>
              <a className='btn btn-primary-c text-uppercase' role='button' href='/login'>Ingresa con tu teléfono o tu correo</a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SecondAction
