import React, { Component } from 'react'
import IconWhatsApp from '../static/img/whatsapp.png'
import './whatsapp.css'

class Whatsapp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      url: 'https://wa.me/51964524202?text=Hola'
    }
  }

  render () {
    return (
      <div className='whatsapp'>
        <a href={this.state.url}>
          <img src={IconWhatsApp} style={{width: '48px', height: '48px'}} alt='Whatsapp' />
        </a>
      </div>
    )
  }
}

export default Whatsapp
