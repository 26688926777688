import React, { Component } from 'react'
import { Carousel, CarouselItem, CarouselIndicators, CarouselControl } from 'reactstrap'
import Profile01 from '../../static/img/perfil01.jpg'
import Profile02 from '../../static/img/perfil02.jpg'
import Profile03 from '../../static/img/perfil03.jpg'
import './Profile.css'

const items = [
  {
    src: Profile01,
    title: 'Deportista',
    description: 'Si eres un deportista, haz que te vean.'
  },
  {
    src: Profile02,
    title: 'Representante',
    description: 'Como representante, podrás impulsar la carrera deportiva de tu hijo.'
  },
  {
    src: Profile03,
    title: 'Reclutador',
    description: 'Buscas nuevos deportistas? Encuentralos aquí.'
  }
]

class Profile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeIndex: 0
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting () {
    this.animating = true
  }

  onExited () {
    this.animating = false
  }

  next () {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous () {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex (newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render () {
    const { activeIndex } = this.state
    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} className='d-block w-100' alt='Hola' />
          <div className='carousel-caption p-3'>
            <h5>{item.title}</h5>
            <p>{item.description}</p>
          </div>
        </CarouselItem>
      )
    })

    return (
      <section>
        <div className='container fuild-small grey-gradient pt-5 pb-5'>
          <div className='separator justify-content-center pb-4 mb-4 d-flex'>
            <div className='separator-line' />
          </div>
          <div className='mb-3 text-center'>
            <h2 className='text-uppercase'>Perfiles</h2>
          </div>
        </div>
        <div className='container fluid-small grey-gradient p-0'>
          <Carousel
            className='home-carousel d-block d-md-none slide'
            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
          >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
            {slides}
            <CarouselControl direction='prev' directionText='Previous' onClickHandler={this.previous} />
            <CarouselControl direction='next' directionText='Next' onClickHandler={this.next} />
          </Carousel>
          <div className='d-none d-md-flex position-relative justify-content-center perfiles-img'>
            <div className='circle circle-big position-absolute' />
            <div className='w-75 d-flex align-items-center position-absolute text-center perfil-box-content'>
              <div className='col-4 perfil-box'>
                <h5 className='text-uppercase py-3'>Representante</h5>
                <p>Como representante, podrás impulsar la carrera deportiva de tu hijo.</p>
              </div>
              <div className='col-4 perfil-box perfil-box-big'>
                <h5 className='text-uppercase py-3'>Deportista</h5>
                <p>Si eres un deportista, tu talento no puede quedar sin descubrirse. Haz que te vean.</p>
              </div>
              <div className='col-4 perfil-box'>
                <h5 className='text-uppercase py-3'>Reclutador</h5>
                <p>Buscas nuevos deportistas? Encuentralos aquí.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Profile
