import API from './api/index'

async function validateSession () {
  const token = await API.auth.verify()
  if (!token.ok) {
    window.localStorage.clear()
    window.location = '/'
  }
}

export default {
  validateSession
}
