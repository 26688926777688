import React, { Component } from 'react'
import './Footer.css'

class Footer extends Component {
  render () {
    return (
      <section>
        <footer>
          <div className='container fluid-small footer'>
            <div className='text-center pt-5'>
              <div className='float-left col-12 col-md-auto mb-2'>
                <p>Copyright Iggy 2018</p>
              </div>
              <div className='float-left col-12 col-md-auto p-0 mb-4 links-footer'>
                <a href='/info/privacy' style={{color: '#00a96e'}} >Políticas de privacidad</a>
                <span> | </span>
                <a href='/info/terms' style={{color: '#00a96e'}} >Términos y condiciones</a>
              </div>
              <div className='float-right col-12 col-md-auto social pb-4' />
            </div>
          </div>
        </footer>
      </section>
    )
  }
}

export default Footer
