import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Authenticated from './Authenticated'
import Landing from './components/landing/Landing'
import Terms from './components/landing/info/terms/Terms'
import Privacy from './components/landing/info/terms/Privacy'
import Login from './components/account/login/Login'
import Register from './components/account/register/Register'
import Home from './components/dashboard/Home'
import Profile from './components/dashboard/profile/Profile'
import ProfileEdit from './components/dashboard/profile/Edit'
import Search from './components/dashboard/search/Search'
import ResetPassword from './components/account/resetPassword/Reset'
import ResetPasswordToken from './components/account/resetPassword/Token'
import RegisterRepresentante from './components/account/register/RegisterRepresentante'

const App = () => {
  return (
    <Router >
      <div>
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route path='/info/terms' component={Terms} />
          <Route path='/info/privacy' component={Privacy} />
          <Route path='/login' component={Login} />
          <Route path='/register' component={Register} />
          <Route path='/peru/representante' component={RegisterRepresentante} />
          <Route exact path='/reset/password' component={ResetPassword} />
          <Route exact path='/reset/password/token/:token' component={ResetPasswordToken} />
          <Authenticated path='/feed' component={Home} />
          <Authenticated path='/search' component={Search} />
          <Authenticated exact path='/user/edit' component={ProfileEdit} />
          <Authenticated path='/user/:username' component={Profile} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
