import React from 'react'
import querySearch from 'stringquery'
import API from '../../../api/index'
import Header from '../Header'
import Detail from './Detail'
import Loader from '../Loader'
import InfiniteScroll from 'react-infinite-scroller'

const Search = (props) => {
  const query = querySearch(props.location.search)
  const [result, setResult] = React.useState([])
  const [hasMore, setHasMore] = React.useState(true)

  async function searchUsers (page) {
    if (query.fullName) {
      query.fullName = query.fullName.replace('+', ' ')
    }
    const response = await API.search.findByPage({ ...query, page: page })
    if (response.ok) {
      if (response.users.length === 0) {
        setHasMore(false)
      } else {
        setResult([...result, ...response.users])
      }
    }
  }

  React.useEffect(() => {
    searchUsers(1)
  }, [])

  var items = result.map((value, index) => {
    return (
      <Detail key={index} profile={value} />
    )
  })

  return (
    <div>
      <Header />
      <section className='companies-info'>
        <div className='container'>
          <div className='companies-list'>
            <InfiniteScroll
              pageStart={0}
              loadMore={searchUsers}
              hasMore={hasMore}
              loader={<Loader />}
            >
              <div className='row'>
                {items}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Search
