import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import API from 'api'
import Header from '../Header'
import CreatePost from '../posts/Create'
import PostDetail from '../posts/Detail'
import Loader from '../Loader'
import { images } from 'static'
import { setItem } from 'api/utils'

const Profile = (props) => {
  const username = props.match.params.username
  const [redirect, setRedirect] = React.useState(false)
  const [profile, setProfile] = React.useState({})
  const [image, setImage] = React.useState(images.ProfileBlank)
  const [posts, setPosts] = React.useState([])
  const [hasMore, setHasMore] = React.useState(true)

  async function loadPosts (page) {
    setHasMore(false)
    const result = await API.posts.list(page, username)
    if (result.posts) {
      if (result.posts.length > 0) {
        setPosts([...posts, ...result.posts])
        setHasMore(true)
      }
    }
  }

  function capitalizeOnlyFirst (string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    } else {
      return ''
    }
  }

  async function getProfile () {
    if (username) {
      const response = await API.account.detail(username)
      if (response.ok) {
        window.document.title = response.fullname
        setProfile(response)
        setImage(response.image || images.ProfileBlank)
      } else {
        setRedirect(true)
      }
    } else {
      setRedirect(true)
    }
  }

  React.useEffect(() => {
    getProfile()
  }, [username])

  let startActivityData
  if (profile.start_activity) {
    if (profile.start_activity === 1) {
      startActivityData = '1 año'
    } else {
      startActivityData = `${profile.start_activity} año(s)`
    }
  }

  function getBase64 (img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  async function changeImage (base64, name, type) {
    await API.account.updateImage({
      image: {
        name_file: name,
        base64: base64,
        format: type
      }
    })
  }

  function beforeUpload (file) {
    if (file) {
      const typeFile = file.type.split('/')[0]
      if (typeFile === 'image') {
        getBase64(file, base64 => {
          changeImage(base64, file.name, file.type)
          setItem('image', base64)
          setImage(base64)
        })
      }
    }
  }

  const postsDetail = posts.map((value, index) => {
    return (
      <PostDetail key={index} post={value} />
    )
  })

  if (redirect) {
    return (
      <Redirect to='/' />
    )
  }

  return (
    <div>
      <Header select='profile' />
      <section className='cover-sec'>
        <img src={images.PortadaBackground} alt='' />
      </section>
      <main>
        <div className='main-section'>
          <div className='container'>
            <div className='main-section-data'>
              <div className='row'>
                <div className='col-lg-3'>
                  <div className='main-left-sidebar'>
                    <div className='user_profile'>
                      <div className='user-pro-img'>
                        <img src={image} alt={profile.first_name} />
                        <div className='add-dp' id='OpenImgUpload'>
                          <input type='file' id='file' onChange={(event) => beforeUpload(event.target.files[0])} />
                          <label htmlFor='file'><i className='fas fa-camera' /></label>
                        </div>
                      </div>
                    </div>
                    <div className='suggestions full-width'>
                      <div className='user_detail_follower text-center'>
                        <ul className='flw-status'>
                          <li>
                            <span>Siguiendo</span>
                            <b>{profile.follow}</b>
                          </li>
                          <li>
                            <span>Seguidores</span>
                            <b>{profile.follower}</b>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='suggestions full-width'>
                      <div className='suggestions-list'>
                        <div className='suggestion-usd'>
                          <div className='sgt-text'>
                            <h4>Posición</h4>
                            <span>{profile.position}</span>
                          </div>
                        </div>
                        <div className='suggestion-usd'>
                          <div className='sgt-text'>
                            <h4>Comienzo de actividad</h4>
                            <span>{startActivityData}</span>
                          </div>
                        </div>
                        <div className='suggestion-usd'>
                          <div className='sgt-text'>
                            <h4>Pie hábil</h4>
                            <span>{capitalizeOnlyFirst(profile.skillful_foot)}</span>
                          </div>
                        </div>
                        <div className='suggestion-usd'>
                          <div className='sgt-text'>
                            <h4>Estatura</h4>
                            <span>{profile.heightPlayer && (`${profile.heightPlayer} cms`)}</span>
                          </div>
                        </div>
                        <div className='suggestion-usd'>
                          <div className='sgt-text'>
                            <h4>Peso</h4>
                            <span>{profile.weightPlayer && (`${profile.weightPlayer} kgs`)}</span>
                          </div>
                        </div>
                        <div className='suggestion-usd'>
                          <div className='sgt-text'>
                            <h4>Club</h4>
                            <span>{profile.club ? profile.club : 'Ninguno'}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='main-ws-sec'>
                    <div className='user-tab-sec'>
                      <h3>{profile.fullname}</h3>
                      <div className='star-descp'>
                        <span>{profile.type_user}</span>
                        {profile.is_premium ? (
                          <i className='fas fa-check-circle text-success' />
                        ) : null}
                      </div>
                      <div className='tab-feed'>
                        <ul>
                          <li data-tab='feed-dd' className='active'>
                            <a href='#' title=''>
                              <i className='fas fa-photo-video fa-lg mb-2' />
                              <span>Publicaciones</span>
                            </a>
                          </li>
                          <li data-tab='info-dd'>
                            <a href='#' title=''>
                              <i className='far fa-address-card fa-lg mb-2' />
                              <span>Biografía</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='product-feed-tab current' id='feed-dd'>
                      {profile.owner && (
                        <CreatePost current='profile' />
                      )}
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={loadPosts}
                        hasMore={hasMore}
                        loader={<Loader key={0} />}>
                        <div className='text-left' key='loading'>
                          {postsDetail}
                        </div>
                      </InfiniteScroll>
                    </div>
                    <div className='product-feed-tab' id='info-dd'>
                      <div className='user-profile-ov'>
                        <h3>Biografía</h3>
                        <p>{profile.biography}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='right-sidebar'>
                    <div className='message-btn'>
                      {profile.owner ? (
                        <Link to='/user/edit'>
                          <i className='fa fa-cogs text-white' /> Editar perfil
                        </Link>
                      ) : (
                        <Link to='/message'>
                          <i className='fa fa-inbox text-white' /> Enviar mensaje
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Profile
