import React from 'react'
import { Link } from 'react-router-dom'
import ProfileBlank from '../../../static/img/img_perfil_blank.png'

const ViewProfile = (props) => {
  const user = props.user || {}

  return (
    <div className='main-left-sidebar no-margin'>
      <div className='user-data full-width'>
        <div className='user-profile'>
          <div className='username-dt'>
            <div className='usr-pic'>
              <img src={user.image || ProfileBlank} alt={user.first_name} />
            </div>
          </div>
          <div className='user-specs'>
            <h3>{user.first_name}</h3>
            <span>{user.type_user}</span>
          </div>
        </div>
        <ul className='user-fw-status'>
          <li>
            <h4>Siguiendo</h4>
            <span>{user.follow}</span>
          </li>
          <li>
            <h4>Seguidores</h4>
            <span>{user.follower}</span>
          </li>
          <li>
            <Link to={`/user/${user.id}`}>Ver perfil</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ViewProfile
