import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Header from './Header'
import Separator from './Separator'
import Profile from './Profile'
import SecondAction from './SecondAction'
import Security from './Security'
import Contact from './Contact'
import Footer from './Footer'
import Us from './Us'
import API from '../../api/index'
import { getItem } from '../../api/utils'
import Whatsapp from '../whatsapp'
import './Landing.css'

class Landing extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: false
    }
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async getLogin () {
    const username = getItem('user')
    const password = getItem('password')
    if (username && password) {
      const response = await API.auth.login(username, password)
      if (response.token) {
        this.updateState('redirect', true)
      }
    }
  }

  componentDidMount () {
    this.getLogin()
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to='/feed' />
      )
    }
    return (
      <div className='wrapper'>
        <Header />
        <Separator />
        <Us />
        <Profile />
        <div className='separator justify-content-center pt-4 pb-4 mt-5 mb-4 d-flex'>
          <div className='separator-line' />
        </div>
        <SecondAction />
        <Separator />
        <Security />
        <Footer />
        <Whatsapp />
      </div>
    )
  }
}

export default Landing
