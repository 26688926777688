import requests from 'axios'
import config from './config'
import { setItem } from './utils'

const APIv2 = 'https://apiv2.iggyball.com/promoter'

async function login (data) {
  const result = await requests.post(`${APIv2}/auth`, data)
  if (result.data._id) {
    setItem('token', result.data.token)
    setItem('firstNamePromoter', result.data.firstName)
  }
  return result.data
}

async function detail () {
  const result = await requests.get(`${APIv2}/detail`, config.configWithToken())
  return result.data
}

async function update (data) {
  const result = await requests.post(`${APIv2}/update`, data, config.configWithToken())
  return result.data
}

async function detailCoordinator (id) {
  const result = await requests.get(`${APIv2}/coordinator/detail/${id}`, config.configWithToken())
  return result.data
}

async function updateCoordinator (id, data) {
  const result = await requests.post(`${APIv2}/coordinator/update/${id}`, data, config.configWithToken())
  return result.data
}

async function create (data) {
  const result = await requests.post(`${APIv2}/create`, data)
  return result.data
}

async function all () {
  const result = await requests.get(`${APIv2}/coordinators`, config.configWithToken())
  return result.data
}

async function createCoordinator (data) {
  const result = await requests.post(`${APIv2}/create/coordinator`, data, config.configWithToken())
  return result.data
}

async function createPlayer (data) {
  const result = await requests.post(`${APIv2}/create/player`, data, config.configWithToken())
  return result.data
}

async function getPlayers () {
  const result = await requests.get(`${APIv2}/coordinartors/players`, config.configWithToken())
  return result.data
}

async function validateToken (token) {
  const result = await requests.get(`${APIv2}/coordinator/validate/token/${token}`, config.configWithToken())
  return result.data
}

async function activeCoordinator (token, password) {
  const result = await requests.post(`${APIv2}/coordinator/active/token/${token}`, {password}, config.configWithToken())
  return result.data
}

export default {
  createCoordinator,
  createPlayer,
  getPlayers,
  activeCoordinator,
  create,
  login,
  all,
  detail,
  update,
  updateCoordinator,
  detailCoordinator,
  validateToken
}
