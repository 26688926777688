import React, { Component } from 'react'
import './Security.css'

class Security extends Component {
  render () {
    return (
      <section className='section-seguridad'>
        <div className='container fluid-small p-0'>
          <div className='d-md-flex col-12 p-0 align-items-center'>
            <div className='square square-img col-12 col-md-7'>
              <div className='circle circle-big position-absolute' />
            </div>
            <div className='square square-text text-center text-md-left p-5 col-12 col-md-6'>
              <h2 className='text-uppercase mt-3 mb-5'>Seguridad</h2>
              <p>En IGGY estamos comprometidos con la protección de los menores y de su seguridad. Queremos crear un ambiente favorable y seguro para ellos y para todos nuestros usuarios.</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Security
