import requests from 'axios'
import config from './config'

const URLv2 = `${config.URLv2}/ban/city`

async function list (state) {
  const result = await requests.get(`${URLv2}/filter-by-state/${state}`, config.configWithToken())
  return result.data.records
}

async function detail (city) {
  const result = await requests.get(`${URLv2}/get-by-id/${city}`, config.configWithToken())
  return result.data
}

export default {
  list,
  detail
}
