import React, { Component } from 'react'
import { Form, FormGroup, Input, FormFeedback } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import API from '../../../api/index'
import Logo from '../../../static/img/logo-green.png'
import './Register.css'

class RegisterRepresentante extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      username: '',
      first_name: '',
      first_name_error: '',
      last_name: '',
      last_name_error: '',
      type_user: 'Representante',
      type_user_error: '',
      password: '',
      password_error: '',
      userName: '',
      userName_error: '',
      redirect: false,
      is_player: false
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  async onSubmit () {
    const response = await API.account.create({
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      type_user: this.state.type_user,
      userName: this.state.userName,
      password: this.state.password
    })
    if (response.errors) {
      const errors = response.errors
      let typeUserError = ''
      let firstNameError = ''
      let lastNameError = ''
      let userNameError = ''
      let passwordError = ''
      if (errors.type_user) {
        typeUserError = errors.type_user.message
      }
      if (errors.first_name) {
        firstNameError = errors.first_name.message
      }
      if (errors.last_name) {
        lastNameError = errors.last_name.message
      }
      if (errors.userName) {
        userNameError = errors.userName.message
      }
      if (errors.password) {
        passwordError = errors.password.message
      }
      this.setState({
        type_user_error: typeUserError,
        first_name_error: firstNameError,
        last_name_error: lastNameError,
        userName_error: userNameError,
        password_error: passwordError
      })
    } else {
      if (this.state.type_user === 'Jugador') {
        this.setState({
          is_player: true
        })
      }
      API.auth.login(this.state.userName, this.state.password).then(() => {
        this.setState({
          redirect: true,
          username: response._id
        })
      })
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to='/user/edit' />)
    }
    return (
      <div className='section-iggy center-block-login'>
        <div className='container-login fuild-small'>
          <div className='text-center width-register d-md-flex col-12 p-0'>
            <div className='margin-auto pt-4'>
              <a href='/'>
                <img src={Logo} alt='IggyBall' />
              </a>
            </div>
          </div>
          <div className='width-form pt-2'>
            <h3 className='text-center'>Crear una cuenta</h3>
            <Form onSubmit={(e) => e.preventDefault()}>
              <div className='row'>
                <FormGroup className='col-12 col-md-12'>
                  <Input invalid={this.state.userName_error ? true : false} type='text' name='userName' id='id_userName' placeholder='Número de celular o correo electrónico' className='form-control-login' onChange={this.onChange} value={this.state.userName} />
                  {this.state.userName_error && (
                    <FormFeedback>{this.state.userName_error}</FormFeedback>
                  )}
                </FormGroup>
              </div>
              <div className='row'>
                <FormGroup className='col-12 col-md-6'>
                  <Input invalid={this.state.first_name_error ? true : false} type='text' name='first_name' id='id_first_name' placeholder='Nombre' className='form-control-login' onChange={this.onChange} value={this.state.first_name} />
                  {this.state.first_name_error && (
                    <FormFeedback>{this.state.first_name_error}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup className='col-12 col-md-6'>
                  <Input invalid={this.state.last_name_error ? true : false} type='text' name='last_name' id='id_last_name' placeholder='Apellidos' className='form-control-login' onChange={this.onChange} value={this.state.last_name} />
                  {this.state.last_name_error && (
                    <FormFeedback>{this.state.last_name_error}</FormFeedback>
                  )}
                </FormGroup>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <FormGroup>
                    <Input invalid={this.state.password_error ? true : false} type='password' name='password' id='password' placeholder='Contraseña' className='form-control-login' onChange={this.onChange} value={this.state.password} />
                    {this.state.password_error && (
                      <FormFeedback>{this.state.password_error}</FormFeedback>
                    )}
                  </FormGroup>
                </div>
              </div>
            </Form>
            <button className='btn btn-success-a rounded-0' onClick={this.onSubmit}>Crear cuenta</button>
            <hr />
            <p className='text-center'>¿Ya te has registrado? <b><a href='/login' style={{color: '#00a96e'}}>Ingresa aquí</a></b></p>
          </div>
        </div>
      </div>
    )
  }
}

export default RegisterRepresentante
