import requests from 'axios'
import config from './config'

const URL = `${config.URL}/like`

export async function create (post) {
  const result = await requests.post(`${URL}`, {post}, config.configWithToken())
  return result.data
}

export async function deleteLike (post) {
  const result = await requests.post(`${URL}/delete`, {post}, config.configWithToken())
  return result.data
}

export default {
  create,
  deleteLike
}
