import React from 'react'
import { Redirect } from 'react-router-dom'
import Header from '../../Header'
import API from '../../../../api/index'

const Player = (props) => {
  const user = props.user
  const [firstName, setFirstName] = React.useState(user.first_name)
  const [lastName, setLastName] = React.useState(user.last_name)
  const [gender, setGender] = React.useState(user.gender)
  const [birthdate, setBirthdate] = React.useState(user.birthdate)
  const [heightPlayer, setHeightPlayer] = React.useState(user.heightPlayer)
  const [school, setSchool] = React.useState(user.school)
  const [weightPlayer, setWeightPlayer] = React.useState(user.weightPlayer)
  const [position, setPosition] = React.useState(user.position)
  const [skillfulFoot, setSkillfulFoot] = React.useState(user.skillful_foot)
  const [dni, setDni] = React.useState(user.dni)
  const [email, setEmail] = React.useState(user.email)
  const [address, setAddress] = React.useState(user.address)
  const [cellphoneHouse, setCellphoneHouse] = React.useState(user.cellphone_house)
  const [biography, setBiography] = React.useState(user.biography)
  const [privacy, setPrivacy] = React.useState(user.privacy)
  const [cellphone, setCellphone] = React.useState(user.cellphone)
  const [club, setClub] = React.useState(user.club)
  const [nationality, setNationality] = React.useState(user.nationality)
  const [startActivity, setStartActivity] = React.useState(user.start_activity)
  const [shoeSize, setShoeSize] = React.useState(user.shoe_size)
  const [dateOfLastMeasurement, setDateOfLastMeasurement] = React.useState(user.date_of_last_measurement)
  const [favoriteTeamOne, setFavoriteTeamOne] = React.useState(user.favorite_team_one)
  const [favoriteTeamTwo, setFavoriteTeamTwo] = React.useState(user.favorite_team_two)
  const [favoriteTeamThree, setFavoriteTeamThree] = React.useState(user.favorite_team_three)
  const [poloSize, setPoloSize] = React.useState(user.polo_size)
  const [shortSize, setShortSize] = React.useState(user.short_size)
  const [chimpunesBrand, setChimpunesBrand] = React.useState(user.chimpunes_brand)
  const [favoriteChimpunesBrandOne, setFavoriteChimpunesBrandOne] = React.useState(user.favorite_chimpunes_brand_one)
  const [favoriteChimpunesBrandTwo, setFavoriteChimpunesBrandTwo] = React.useState(user.favorite_chimpunes_brand_two)
  const [dateOfLastMeasurementOne, setDateOfLastMeasurementOne] = React.useState(user.date_of_last_measurement_one)
  const [dateOfTheLastTimeItWasWeighed, setDateOfThheLastTimeItWasWeighed] = React.useState(user.date_of_the_last_time_it_was_weighed)
  const [fatherName, setFatherName] = React.useState(user.father_name)
  const [fatherHeight, setFatherHeight] = React.useState(user.father_height)
  const [professionFather, setProfessionFather] = React.useState(user.profession_father)
  const [fatherCellphone, setFatherCellphone] = React.useState(user.father_cellphone)
  const [motherName, setMotherName] = React.useState(user.mother_name)
  const [motherHeight, setMotherHeight] = React.useState(user.mother_height)
  const [professionMother, setProfessionMother] = React.useState(user.profession_mother)
  const [motherCellphone, setMotherCellphone] = React.useState(user.mother_cellphone)
  const [redirect, setRedirect] = React.useState(false)
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [country, setCountry] = React.useState('')
  const [state, setState] = React.useState('')
  const [city, setCity] = React.useState('')

  React.useEffect(() => {
    getCountries()
    if (props.user.city) {
      getCity()
    }
  }, [])

  async function getCity () {
    let response = await API.city.detail(props.user.city)
    response = response.record
    if (response.city) {
      setCountry(response.country.id)
      getStates(response.country.id)
      setState(response.state.id)
      getCities(response.state.id)
      setCity(response.city.id)
    }
  }

  function capitalizeOnlyFirst (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  function createRow (values) {
    const row = []
    values.map(value => {
      const name = capitalizeOnlyFirst(value.name)
      return row.push(<option value={value.id} key={value.id}>{name}</option>)
    })
    return row
  }

  async function getCountries () {
    const response = await API.country.list()
    const countriesRow = createRow(response)
    setCountries(countriesRow)
  }

  async function getStates (idCountry) {
    const response = await API.state.list(idCountry)
    const stateRow = createRow(response)
    setStates(stateRow)
  }

  async function getCities (idState) {
    const response = await API.city.list(idState)
    const citiesRow = createRow(response)
    setCities(citiesRow)
  }

  async function onSubmit () {
    const response = await API.account.updatePlayer({
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      email: email,
      cellphone: cellphone,
      biography: biography,
      privacy: privacy,
      birthdate: birthdate,
      city: city,
      club: club,
      position: position,
      start_activity: startActivity,
      shoe_size: shoeSize,
      date_of_last_measurement: dateOfLastMeasurement,
      favorite_team_one: favoriteTeamOne,
      favorite_team_two: favoriteTeamTwo,
      favorite_team_three: favoriteTeamThree,
      polo_size: poloSize,
      short_size: shortSize,
      chimpunes_brand: chimpunesBrand,
      favorite_chimpunes_brand_one: favoriteChimpunesBrandOne,
      favorite_chimpunes_brand_two: favoriteChimpunesBrandTwo,
      heightPlayer: heightPlayer,
      date_of_last_measurement_one: dateOfLastMeasurementOne,
      weightPlayer: weightPlayer,
      date_of_the_last_time_it_was_weighed: dateOfTheLastTimeItWasWeighed,
      school: school,
      skillful_foot: skillfulFoot,
      dni: dni,
      address: address,
      cellphone_house: cellphoneHouse,
      nationality: nationality,
      father_name: fatherName,
      father_height: fatherHeight,
      profession_father: professionFather,
      father_cellphone: fatherCellphone,
      mother_name: motherName,
      mother_height: motherHeight,
      profession_mother: professionMother,
      mother_cellphone: motherCellphone
    })
    if (response.ok) {
      setRedirect(true)
    }
  }

  if (redirect) {
    return (
      <Redirect to='/feed' />
    )
  }

  return (
    <>
      <Header select='profile' />
      <section className='profile-account-setting'>
        <div className='container'>
          <div className='account-tabs-setting'>
            <div className='row'>
              <div className='col-lg-3'>
                <div className='acc-leftbar'>
                  <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                    <a className='nav-item nav-link active' id='nav-general-information' data-toggle='tab' href='#general-information' role='tab' aria-controls='general-information' aria-selected='true'><i className='fa fa-user-secret' />Información general</a>
                    <a className='nav-item nav-link' id='place' data-toggle='tab' href='#place-live' role='tab' aria-controls='place-live' aria-selected='false'><i className='fa fa-address-card' />Lugar dónde vives</a>
                    <a className='nav-item nav-link' id='nav-information-about' data-toggle='tab' href='#information-about' role='tab' aria-controls='information-about' aria-selected='false'><i className='fa fa-info' />Información sobre ti</a>
                    <a className='nav-item nav-link' id='nav-information-basic' data-toggle='tab' href='#information-basic' role='tab' aria-controls='information-basic' aria-selected='false'><i className='fa fa-vcard-o' />Información básica</a>
                  </div>
                </div>
              </div>
              <div className='col-lg-9'>
                <div className='tab-content' id='nav-tabContent'>
                  <div className='tab-pane fade show active' id='general-information' role='tabpanel' aria-labelledby='nav-general-information'>
                    <div className='acc-setting'>
                      <h3>Información general</h3>
                      <form onSubmit={(event) => event.preventDefault()}>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Nombre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Nombre' value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                                <i className='fa fa-user' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Apellido</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Apellido' value={lastName} onChange={(event) => setLastName(event.target.value)} />
                                <i className='fa fa-user' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Género</h5>
                              <div className='cpp-fiel'>
                                <select className='form-control' style={{ '-webkit-appearance': 'menulist-button' }} value={gender} onChange={(event) => setGender(event.target.value)}>
                                  <option value='masculino'>Masculino</option>
                                  <option value='femenino'>Femenino</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Tipo de perfil</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={user.type_user} disabled />
                                <i className='fa fa-male' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Correo electrónico</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Correo electrónico' value={email} onChange={(event) => setEmail(event.target.value)} />
                                <i className='fa fa-envelope' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Celular</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Celular' value={cellphone} onChange={(event) => setCellphone(event.target.value)} />
                                <i className='fa fa-mobile' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Fecha de nacimiento</h5>
                              <div className='cpp-fiel'>
                                <input type='date' value={birthdate} onChange={(event) => setBirthdate(event.target.value)} />
                                <i className='fa fa-birthday-cake' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Cuenta privada</h5>
                              <div className='custom-control custom-checkbox'>
                                <input type='checkbox' className='custom-control-input' id='privacy' checked={privacy} onChange={() => setPrivacy(!privacy)} />
                                <label className='custom-control-label' for='privacy'>Si tu cuenta es privada, solo las personas que apruebes podrán ver tus fotos y videos en Iggyball. Esto no afectará a tus seguidores actuales.</label>
                              </div>
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='cp-field'>
                              <h5>Biografiía</h5>
                              <textarea value={biography} onChange={(event) => setBiography(event.target.value)} rows={3} />
                            </div>
                          </div>
                        </div>
                        <div className='save-stngs pd2'>
                          <ul>
                            <li><button type='submit' onClick={() => onSubmit()}>Guardar cambios</button></li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='tab-pane fade' id='place-live' role='tabpanel' aria-labelledby='place'>
                    <div className='acc-setting'>
                      <h3>Lugar dónde vives</h3>
                      <form onSubmit={(event) => event.preventDefault()}>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>País</h5>
                              <div className='cpp-fiel'>
                                <select
                                  className='form-control'
                                  style={{ '-webkit-appearance': 'menulist-button' }}
                                  value={country}
                                  onChange={(event) => {
                                    setCountry(event.target.value)
                                    getStates(event.target.value)
                                  }}
                                >
                                  <option value=''>Seleccione un país</option>
                                  {countries}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Estado / Departamento</h5>
                              <div className='cpp-fiel'>
                                <select
                                  className='form-control'
                                  style={{ '-webkit-appearance': 'menulist-button' }}
                                  value={state}
                                  onChange={(event) => {
                                    setState(event.target.value)
                                    getCities(event.target.value)
                                  }}
                                >
                                  <option value=''>Seleccione un estado / departamento</option>
                                  {states}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Ciudad</h5>
                              <div className='cpp-fiel'>
                                <select className='form-control' style={{ '-webkit-appearance': 'menulist-button' }} value={city} onChange={(event) => setCity(event.target.value)}>
                                  <option value=''>Seleccione una ciudad</option>
                                  {cities}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='save-stngs pd2'>
                          <ul>
                            <li><button type='submit' onClick={() => onSubmit()}>Guardar cambios</button></li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='tab-pane fade' id='information-about' role='tabpanel' aria-labelledby='nav-information-about'>
                    <div className='acc-setting'>
                      <h3>Información sobre ti</h3>
                      <form onSubmit={(event) => event.preventDefault()}>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Club o equipo (en el que juega)</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Club' value={club} onChange={(event) => setClub(event.target.value)} />
                                <i className='fa fa-shirtsinbulk' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Puesto / Posición</h5>
                              <div className='cpp-fiel'>
                                <select className='form-control' style={{ '-webkit-appearance': 'menulist-button' }} value={position} onChange={(event) => setPosition(event.target.value)}>
                                  <option value='Arquero'>Arquero</option>
                                  <option value='Defensor'>Defensor</option>
                                  <option value='Mediocampista'>Mediocampista</option>
                                  <option value='Delantero'>Delantero</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Hace cuantos años juega?</h5>
                              <div className='cpp-fiel'>
                                <input type='number' value={startActivity} onChange={(event) => setStartActivity(event.target.value)} />
                                <i className='fa fa-globe' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Talla calzado</h5>
                              <div className='cpp-fiel'>
                                <input type='number' value={shoeSize} onChange={(event) => setShoeSize(event.target.value)} />
                                <i className='fa fa-tag' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Fecha de la ultima vez que se midió</h5>
                              <div className='cpp-fiel'>
                                <input type='date' value={dateOfLastMeasurement} onChange={(event) => setDateOfLastMeasurement(event.target.value)} />
                                <i className='fa fa-calendar' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Equipo favorito 1</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={favoriteTeamOne} onChange={(event) => setFavoriteTeamOne(event.target.value)} />
                                <i className='fa fa-tag' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Equipo favorito 2</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={favoriteTeamTwo} onChange={(event) => setFavoriteTeamTwo(event.target.value)} />
                                <i className='fa fa-tag' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Equipo favorito 3</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={favoriteTeamThree} onChange={(event) => setFavoriteTeamThree(event.target.value)} />
                                <i className='fa fa-tag' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Talla polo</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={poloSize} onChange={(event) => setPoloSize(event.target.value)} />
                                <i className='fa fa-shirtsinbulk' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Talla short</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={shortSize} onChange={(event) => setShortSize(event.target.value)} />
                                <i className='fa fa-shirtsinbulk' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Marca chimpunes</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={chimpunesBrand} onChange={(event) => setChimpunesBrand(event.target.value)} />
                                <i className='fa fa-shirtsinbulk' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Marca chimpunes favorita 1</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={favoriteChimpunesBrandOne} onChange={(event) => setFavoriteChimpunesBrandOne(event.target.value)} />
                                <i className='fa fa-shirtsinbulk' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Marca chimpunes favorita 2</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={favoriteChimpunesBrandTwo} onChange={(event) => setFavoriteChimpunesBrandTwo(event.target.value)} />
                                <i className='fa fa-shirtsinbulk' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Estatura (cm)</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={heightPlayer} onChange={(event) => setHeightPlayer(event.target.value)} />
                                <i className='fa fa-plus' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Fecha de la ultima vez que se midió</h5>
                              <div className='cpp-fiel'>
                                <input type='date' value={dateOfLastMeasurementOne} onChange={(event) => setDateOfLastMeasurementOne(event.target.value)} />
                                <i className='fa fa-calendar' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Peso (kg)</h5>
                              <div className='cpp-fiel'>
                                <input type='number' value={weightPlayer} onChange={(event) => setWeightPlayer(event.target.value)} />
                                <i className='fa fa-plus' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Fecha de la ultima vez que se pesó</h5>
                              <div className='cpp-fiel'>
                                <input type='date' value={dateOfTheLastTimeItWasWeighed} onChange={(event) => setDateOfThheLastTimeItWasWeighed(event.target.value)} />
                                <i className='fa fa-calendar' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Escuela / Colegio</h5>
                              <div className='cpp-fiel'>
                                <input type='text' value={school} onChange={(event) => setSchool(event.target.value)} />
                                <i className='fa fa-shirtsinbulk' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Pie hábil</h5>
                              <div className='cpp-fiel'>
                                <select className='form-control' style={{ '-webkit-appearance': 'menulist-button' }} value={skillfulFoot} onChange={(event) => setSkillfulFoot(event.target.value)}>
                                  <option value=''>Selecciona un pie hábil</option>
                                  <option value='derecho'>Derecho</option>
                                  <option value='izquierdo'>Izquierdo</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='save-stngs pd2'>
                          <ul>
                            <li><button type='submit' onClick={() => onSubmit()}>Guardar cambios</button></li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='tab-pane fade' id='information-basic' role='tabpanel' aria-labelledby='information-basic'>
                    <div className='acc-setting'>
                      <h3>Información básica</h3>
                      <form onSubmit={(event) => event.preventDefault()}>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>DNI</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='DNI' value={dni} onChange={(event) => setDni(event.target.value)} />
                                <i className='fa fa-drivers-license-o' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Dirección</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Dirección' value={address} onChange={(event) => setAddress(event.target.value)} />
                                <i className='fa fa-map-marker' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Teléfono casa</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Teléfono casa' value={cellphoneHouse} onChange={(event) => setCellphoneHouse(event.target.value)} />
                                <i className='fa fa-mobile' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Nacionalidad</h5>
                              <div className='cpp-fiel'>
                                <select
                                  className='form-control'
                                  style={{ '-webkit-appearance': 'menulist-button' }}
                                  value={nationality}
                                  onChange={(event) => {
                                    setNationality(event.target.value)
                                  }}
                                >
                                  <option value=''>Seleccione un país</option>
                                  {countries}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Nombre del padre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Nombre del padre' value={fatherName} onChange={(event) => setFatherName(event.target.value)} />
                                <i className='fa fa-user' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Estatura del padre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Estatura del padre' value={fatherHeight} onChange={(event) => setFatherHeight(event.target.value)} />
                                <i className='fa fa-plus' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Profesión del padre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Profesión del padre' value={professionFather} onChange={(event) => setProfessionFather(event.target.value)} />
                                <i className='fa fa-male' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Celular del padre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Celular del padre' value={fatherCellphone} onChange={(event) => setFatherCellphone(event.target.value)} />
                                <i className='fa fa-mobile' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Nombre de la madre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Nombre de la madre' value={motherName} onChange={(event) => setMotherName(event.target.value)} />
                                <i className='fa fa-user' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Estatura de la madre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Estatura de la madre' value={motherHeight} onChange={(event) => setMotherHeight(event.target.value)} />
                                <i className='fa fa-plus' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Profesión de la madre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Profesión de la madre' value={professionMother} onChange={(event) => setProfessionMother(event.target.value)} />
                                <i className='fa fa-female' />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='cp-field'>
                              <h5>Celular de la madre</h5>
                              <div className='cpp-fiel'>
                                <input type='text' placeholder='Celular de la madre' value={motherCellphone} onChange={(event) => setMotherCellphone(event.target.value)} />
                                <i className='fa fa-mobile' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='save-stngs pd2'>
                          <ul>
                            <li><button type='submit' onClick={() => onSubmit()}>Guardar cambios</button></li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Player
