import React from 'react'
import { Link } from 'react-router-dom'
import { Carousel } from 'antd'
import { Player, ControlBar } from 'video-react'
import ProfileBlank from '../../../static/img/img_perfil_blank.png'
import LogoClock from '../../../static/images/clock.png'
import LogoLiked from '../../../static/images/liked-img.png'
import API from '../../../api/index'
import './Posts.css'

const Detail = (props) => {
  const post = props.post
  let image = post.user.image
  if (image) {
    image = `https://api.iggyball.com/static/${image}`
  }
  const [likes, setLikes] = React.useState(post.likes)
  const [like, setLike] = React.useState(post.id_like)

  async function createLike () {
    const response = await API.like.create(post.id)
    if (response._id && !like) {
      setLikes(likes + 1)
      setLike(response._id)
    }
  }

  async function deleteLike () {
    const response = await API.like.deleteLike(post.id)
    if (response.deletePostLike && like) {
      setLikes(likes - 1)
      setLike(false)
    }
  }

  function content (file, key) {
    if (file.format === 'image') {
      return (
        <div className='img-post-container' key={file.id}>
          <img className='img-fluid img-post' src={file.url} alt={post.user.full_name} />
        </div>
      )
    } else if (file.format === 'video') {
      return (
        <div key={file.id}>
          <Player>
            <source src={file.url} />
            <ControlBar autoHide={false} />
          </Player>
        </div>
      )
    } else {
      return null
    }
  }

  const files = post.files
  let result = []
  for (let index = 0; index < files.length; index++) {
    const element = files[index]
    result.push(content(element, index))
  }

  return (
    <div className='posts-section'>
			<div className='post-bar'>
				<div className='post_topbar'>
					<div className='usy-dt'>
            <Link to={`/user/${post.user.id}`}>
              <img src={image || ProfileBlank} alt={post.user.full_name} />
              <div className='usy-name'>
                  <h3>{post.user.full_name}</h3>
                <span>
                  <img src={LogoClock} alt='Time' /> {post.created}
                </span>
              </div>
            </Link>
					</div>
				</div>
				<div className="job_descp mt-2">
          <Carousel autoplay>
            {result}
          </Carousel>
          <p>{post.content}</p>
				</div>
				<div className="job-status-bar">
					<ul className="like-com">
						<li>
              {like ? (
                <p className='active' onClick={deleteLike}>
                  <i className="fa fa-heart"></i> Me gusta
                </p>
              ) : (
                <p onClick={createLike}>
                  <i className="fa fa-heart"></i> Me gusta
                </p>
              )}
              {likes > 0 && (
                <img src={LogoLiked} alt='Liked' />
              )}
              <span>{likes}</span>
						</li>
					</ul>
				</div>
			</div>
    </div>
  )
}

export default Detail
