import requests from 'axios'
import config from './config'

const URLv2 = `${config.URLv2}/ban/country`

export async function list () {
  const result = await requests.get(`${URLv2}/all`, config.configWithToken())
  return result.data.records
}

export default {
  list
}
