import React, { Component } from 'react'
import './Separator.css'

class Separator extends Component {
  render () {
    return (
      <div className='separator justify-content-center pt-4 pb-4 mt-4 mb-4 d-none d-md-flex'>
        <div className='separator-line' />
      </div>
    )
  }
}

export default Separator
