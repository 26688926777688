import requests from 'axios'
import config from './config'

const URL = `${config.URL}/search`
const URLv2 = `${config.URLv2}/search`

export async function findByName (page, name) {
  const result = await requests.get(`${URL}/list/${page}/${name}`, config.configWithToken())
  return result.data
}

export async function find (page, fields) {
  const result = await requests.post(`${URL}/list/${page}`, fields, config.configWithToken())
  return result.data
}

export async function findByPage (data) {
  const result = await requests.post(`${URLv2}/query`, data, config.configWithToken())
  return result.data
}

export default {
  findByName,
  find,
  findByPage
}
