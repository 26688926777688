import account from './account'
import auth from './auth'
import subscription from './contact'
import country from './country'
import state from './state'
import city from './city'
import player from './player'
import followers from './followers'
import messages from './message'
import visits from './visits'
import posts from './posts'
import search from './search'
import password from './password'
import landing from './landing'
import like from './like'
import academy from './academy'
import notification from './notification'
import promoter from './promoter'
import config from './config'
export * from './utils'

const API = {
  account,
  config,
  auth,
  subscription,
  country,
  state,
  city,
  player,
  followers,
  messages,
  visits,
  posts,
  search,
  password,
  landing,
  like,
  academy,
  notification,
  promoter
}

export default API
