import requests from 'axios'
import config from './config'

const URL = `${config.URL}/followers`

export async function verify (follow) {
  const result = await requests.get(`${URL}/verify/${follow}`, config.configWithToken())
  return result.data
}

export async function follow (user) {
  const result = await requests.post(`${URL}`, {follow: user}, config.configWithToken())
  return result.data
}

export async function unfollow (follow) {
  const result = await requests.post(`${URL}/delete/${follow}`, {}, config.configWithToken())
  return result.data
}

export async function count (user) {
  const result = await requests.get(`${URL}/count/followers/${user}`, config.configWithToken())
  return result.data
}

export async function approved (id) {
  const result = await requests.get(`${URL}/approved/${id}`, config.configWithToken())
  return result.data
}

export async function notApproved (id) {
  const result = await requests.get(`${URL}/not/approved/${id}`, config.configWithToken())
  return result.data
}

export default {
  verify,
  follow,
  unfollow,
  count,
  approved,
  notApproved
}
