import React from 'react'
import Header from './Header'
import CreatePost from './posts/Create'
import PostDetail from './posts/Detail'
import InfiniteScroll from 'react-infinite-scroller'
import API from '../../api/index'
import { getItem } from '../../api/utils'
import Whatsapp from '../whatsapp'
import ViewProfile from './profile/View'
import Loader from './Loader'

const Home = (props) => {
  const username = getItem('username')
  const [posts, setPosts] = React.useState([])
  const [hasMore, setHasMore] = React.useState(true)
  const [user, setUser] = React.useState({})

  async function loadPosts (page) {
    setHasMore(false)
    const result = await API.posts.listAll(page)
    if (result.posts) {
      if (result.posts.length > 0) {
        setPosts([...posts, ...result.posts])
        setHasMore(true)
      }
    }
  }

  async function detailProfile () {
    const profile = await API.account.detail(username)
    setUser(profile)
  }

  React.useEffect(() => {
    window.document.title = 'IggyBall'
    detailProfile()
  }, [])

  const postsDetail = posts.map((value, index) => {
    return (
      <PostDetail key={index} post={value} />
    )
  })

  return (
    <div>
      <Header />
      <main>
			  <div className="main-section">
				  <div className="container">
					  <div className="main-section-data">
						  <div className="row">
							  <div className="col-lg-3 col-md-4 pd-left-none no-pd">
								  <ViewProfile user={user} />
                </div>
                <div className="col-lg-6 col-md-8 no-pd">
								  <div className="main-ws-sec">
                    <CreatePost current='home' />
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={loadPosts}
                      hasMore={hasMore}
                      loader={<Loader key={0} />}>
                      <div className='text-left' key='loading'>
                        {postsDetail}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Whatsapp />
    </div>
  )
}

export default Home
