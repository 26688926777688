import requests from 'axios'
import config from './config'

const URL = `${config.URL}/account`

export async function create (data) {
  var form = new window.FormData()
  Object.keys(data).forEach(function (key) {
    form.append(key, data[key])
  })
  const result = await requests.post(`${URL}/player`, form, config.configWithTokenAndMultipart())
  return result.data
}

export async function list (user) {
  const result = await requests.get(`${URL}/${user}/list/players`, config.configWithToken())
  return result.data
}

export async function detail (player) {
  const result = await requests.get(`${URL}/detail/${player}`, config.configWithToken())
  return result.data
}

export async function listAll () {
  const result = await requests.get(`${URL}/list`, config.configWithToken())
  return result.data
}

export default {
  create,
  list,
  listAll,
  detail
}
