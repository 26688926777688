import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import API from '../../../api/index'
import { getItem } from '../../../api/utils'
import Logo from '../../../static/img/logo-green.png'
import CopyIcon from '../../../static/images/copy-icon.png'
import './Login.css'

const Login = (props) => {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState(false)
  const [messageError, setMessageError] = React.useState('')
  const [redirect, setRedirect] = React.useState(false)

  async function onSubmit (event) {
    event.preventDefault()
    if (username && password) {
      const response = await API.auth.login(username, password)
      if (!response.ok) {
        setError(true)
        setMessageError('Usuario o contraseña incorrectos intentalo nuevamente')
      } else {
        setRedirect(true)
      }
    } else {
      setError(true)
      setMessageError('Usuario y contraseña son obligatorios')
    }
  }

  async function getLogin () {
    const username = getItem('user')
    const password = getItem('password')
    if (username && password) {
      const response = await API.auth.login(username, password)
      if (response.token) {
        setRedirect(true)
      }
    }
  }

  React.useEffect(() => {
    getLogin()
  }, [])

  if (redirect) {
    return (<Redirect to='/feed' />)
  }

  return (
    <div className='sign-in'>
      <div className='wrapper-login' style={{backgroundColor: '#00a96e'}}>
        <div className='sign-in-page'>
			    <div className='signin-popup'>
				    <div className='signin-pop'>
					    <div className='row'>
						    <div className='col-lg-6'>
							    <div className='cmp-info'>
								    <div className='cm-logo'>
                      <Link to='/'>
                        <img src={Logo} alt='Iggyball' />
                      </Link>
                      <p>Conecta padres e hijos deportistas con reclutadores y clubes deportivos</p>
                      <br />
								    </div>
							    </div>
						    </div>
						    <div className='col-lg-6'>
							    <div className='login-sec'>
								    <ul className='sign-control'>
									    <li className='current'>
                        <Link to='/login'>Iniciar sesión</Link>
                      </li>
									    <li>
                        <Link to='/register'>Regístrate</Link>
                      </li>
								    </ul>
								    <div className='sign_in_sec current'>
									    <h3>Iniciar sesión</h3>
									    <form onClick={(event) => event.preventDefault()}>
										    <div className='row'>
                          {error && (
                            <div className='col-lg-12 no-pdd mb-2'>
                              <p className='text-danger'>{messageError}</p>
                            </div>
                          )}
											    <div className='col-lg-12 no-pdd'>
												    <div className='sn-field'>
													    <input
                                type='text'
                                placeholder='Correo electrónico o celular'
                                onChange={(event) => setUsername(event.target.value)}
                                value={username}
                              />
													    <i className='la la-user'></i>
												    </div>
											    </div>
											    <div className='col-lg-12 no-pdd'>
												    <div className='sn-field'>
													    <input
                                type='password'
                                placeholder='Contraseña'
                                onChange={(event) => setPassword(event.target.value)}
                                value={password}
                              />
													    <i className='la la-lock'></i>
												    </div>
											    </div>
											    {/* <div className='col-lg-12 no-pdd'>
												    <div className='checky-sec'>
                              <Link to='/reset/password'>¿Olvidaste tu contraseña?</Link>
												    </div>
											    </div> */}
											    <div className='col-lg-12 no-pdd'>
												    <button type='submit' onClick={(event) => onSubmit(event)}>Iniciar sesión</button>
											    </div>
										    </div>
									    </form>
								    </div>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
			    <div className='footy-sec'>
				    <div className='container'>
					    <ul>
						    <li>
                  <Link to='/info/privacy'>Políticas de privacidad</Link>
                </li>
						    <li>
                  <Link to='/info/terms'>Términos y condiciones</Link>
                </li>
					    </ul>
					    <p><img src={CopyIcon} alt='Copyright 2018' />Copyright 2018 - Iggyball</p>
				    </div>
			    </div>
		    </div>
      </div>
    </div>
  )
}

export default Login
