import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import registerServiceWorker from './registerServiceWorker'
import * as Sentry from '@sentry/browser'
import './index.css'
import './static/css/animate.css'
import './static/css/bootstrap.min.css'
import './static/css/line-awesome.css'
import './static/css/line-awesome-font-awesome.min.css'
import './static/vendor/fontawesome-free/css/all.min.css'
import './static/css/font-awesome.min.css'
import './static/css/jquery.mCustomScrollbar.min.css'
import './static/lib/slick/slick.css'
import './static/lib/slick/slick-theme.css'
import './static/css/style.css'
import './static/css/responsive.css'

if (window.location.hostname === 'www.iggyball.com') {
  Sentry.init({dsn: 'https://561e0d8460c14def907e459fb045a44f@sentry.io/1473159'})
}

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'))
registerServiceWorker()
