import React, { Component } from 'react'
import { Form, FormGroup, Label, Input, Alert } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import API from '../../../api/index'
import Logo from '../../../static/img/logo-green.png'

class Token extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      password: '',
      repeatPassword: '',
      token: props.match.params.token,
      error: false,
      messageError: '',
      messageSuccess: '',
      redirect: false,
      redirectLogin: false
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  updateInfo (key, value) {
    this.setState({
      [key]: value
    })
  }

  onChange (event) {
    this.updateInfo(event.target.name, event.target.value)
  }

  async onSubmit () {
    this.updateInfo('error', false)
    this.updateInfo('messageError', '')
    if (this.state.password && this.state.repeatPassword) {
      if (this.state.password === this.state.repeatPassword) {
        const result = await API.password.changePassword(this.state.token, this.state.password)
        if (result._id) {
          await API.auth.login(result.userName, this.state.password)
          this.updateInfo('redirectLogin', true)
        } else {
          this.updateInfo('redirect', true)
        }
      } else {
        this.updateInfo('error', true)
        this.updateInfo('messageError', 'Las contraseñas no coinciden')
      }
    } else {
      this.updateInfo('error', true)
      this.updateInfo('messageError', 'Completa los campos para cambiar la contraseña')
    }
  }

  async verifyToken () {
    const result = await API.password.validateToken(this.state.token)
    if (!result.token) {
      this.updateInfo('redirect', true)
    } else {
      this.updateInfo('token', result.token)
    }
  }

  componentDidMount () {
    this.verifyToken()
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to='/feed' />)
    }
    if (this.state.redirectLogin) {
      return (<Redirect to='/feed' />)
    }
    return (
      <div className='section-iggy center-block-login'>
        <div className='container-reset fuild-small'>
          <div className='text-center width-login d-md-flex col-12 p-0'>
            <div className='margin-auto pt-4'>
              <a href='/'>
                <img src={Logo} alt='IggyBall' />
              </a>
              <h5 className='text-uppercase mt-3 font-weight-bold'>Crear una nueva contraseña</h5>
            </div>
          </div>
          <div className='width-form'>
            {this.state.error && (
              <Alert color='danger'>
                {this.state.messageError}
              </Alert>
            )}
            <Form>
              <FormGroup>
                <Label for='password' className='font-weight-light sub-title-login'>Contraseña</Label>
                <Input type='password' name='password' id='password' className='form-control-login' onChange={this.onChange} value={this.state.password} />
              </FormGroup>
            </Form>
            <Form>
              <FormGroup>
                <Label for='repeatPassword' className='font-weight-light sub-title-login'>Repetir contraseña nueva</Label>
                <Input type='password' name='repeatPassword' id='repeatPassword' className='form-control-login' onChange={this.onChange} value={this.state.repeatPassword} />
              </FormGroup>
            </Form>
            <p className='text-center'>
              <a className='btn btn-success-a rounded-0' onClick={this.onSubmit}>Cambiar mi contraseña</a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Token
