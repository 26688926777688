import requests from 'axios'
import config from './config'

const URL_CONVERSATIONS = `${config.URLv2}/conversation`
const URL_MESSAGE = `${config.URLv2}/message`

export async function create (to, message) {
  const result = await requests.post(`${URL_MESSAGE}/create`, {to, message}, config.configWithToken())
  return result.data
}

export async function conversations () {
  const result = await requests.get(`${URL_CONVERSATIONS}/list`, config.configWithToken())
  return result.data
}

export async function detail (_id) {
  const result = await requests.get(`${URL_CONVERSATIONS}/detail/${_id}`, config.configWithToken())
  return result.data
}

export async function create_conversation (to) {
  const result = await requests.post(`${URL_CONVERSATIONS}/create`, {to}, config.configWithToken())
  return result.data
}

export default {
  create,
  conversations,
  detail,
  create_conversation
}
