import requests from 'axios'
import config from './config'

const URLv2 = `${config.URLv2}/ban/state`

export async function list (country) {
  const result = await requests.get(`${URLv2}/filter-by-country/${country}`, config.configWithToken())
  return result.data.records
}

export default {
  list
}
