import requests from 'axios'
import config from './config'
import { setItem, getItem } from './utils'

const URL = `${config.URL}/account`
const URLv3 = `${config.URLv2}/account`

export async function create (data) {
  const result = await requests.post(`${URLv3}/create`, data)
  return result.data
}

export async function detail (username = undefined) {
  let result = {}
  if (username) {
    result = await requests.get(`${URLv3}/detail/${username}`, config.configWithToken())
  } else {
    result = await requests.get(`${URLv3}/detail`, config.configWithToken())
  }
  if (getItem('username') === result.data.id) {
    setItem('full_name', result.data.first_name)
    if (result.data.image) {
      setItem('image', result.data.image)
    }
  }
  return result.data
}

export async function updateImage (data) {
  const typeUser = getItem('type_user')
  if (typeUser === 'Jugador') {
    await updatePlayer(data)
  }
  if (typeUser === 'Club') {
    await updateClub(data)
  }
  if (typeUser === 'Representante' || typeUser === 'Reclutador') {
    await updateCommon(data)
  }
}

export async function updatePlayer (data) {
  const result = await requests.post(`${URLv3}/player/update`, data, config.configWithToken())
  return result.data
}

export async function updateClub (data) {
  const result = await requests.post(`${URLv3}/club/update`, data, config.configWithToken())
  return result.data
}

export async function updateCommon (data) {
  const result = await requests.post(`${URLv3}/common/update`, data, config.configWithToken())
  return result.data
}

export async function listByTypeUser (typeUser) {
  const result = await requests.post(`${URL}/detail/type-user`, {type_user: typeUser}, config.configWithToken())
  return result.data
}

export async function suggestions () {
  const result = await requests.get(`${URL}/suggestions`, config.configWithToken())
  return result.data
}

export default {
  create,
  detail,
  listByTypeUser,
  updateImage,
  suggestions,
  updatePlayer,
  updateClub,
  updateCommon
}
