import requests from 'axios'
import config from './config'

const URL = `${config.URL}/academy`

export async function all (page) {
  const result = await requests.get(`${URL}/list/${page}`)
  return result.data
}

export default {
  all
}
