import React from 'react'
import { Link } from 'react-router-dom'
import { images } from 'static'

const Detail = (props) => {
  return (
    <div className='col-lg-3 col-md-4 col-sm-6'>
      <div className='company_profile_info'>
        <div className='company-up-info'>
          <img src={props.profile.image || images.ProfileBlank} alt={props.profile.fullName} />
          <Link to={`/user/${props.profile.id}`}>
            <h3>{props.profile.fullName}</h3>
          </Link>
          <h4>
            {props.profile.type_user}
            {props.profile.is_premium ? (
              <i className='fas fa-check-circle text-success ml-1' />
            ) : null}
          </h4>
        </div>
        <Link to={`/user/${props.profile.id}`} className='view-more-pro text-success'>Ver perfil</Link>
      </div>
    </div>
  )
}

export default Detail
