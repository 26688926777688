import requests from 'axios'
import account from './account'
import config from './config'
import { setItem } from './utils'

const URL = `${config.URLv2}/auth`

export async function login (userName, password) {
  const result = await requests.post(`${URL}/token`, { userName, password })
  if (result.data.ok) {
    setItem('token', result.data.token)
    setItem('full_name', result.data.full_name)
    setItem('username', result.data._id)
    setItem('type_user', result.data.profile)
    setItem('is_premium', result.data.is_premium)
    await account.detail(result.data._id)
  }
  return result.data
}

export async function verify () {
  const result = await requests.get(`${URL}/verify`, config.configWithToken())
  return result.data
}

export default {
  login,
  verify
}
