import requests from 'axios'
import config from './config'

const URLv2 = `${config.URLv2}/post`

export async function create (data) {
  const result = await requests.post(`${URLv2}/create`, data, config.configWithToken())
  return result.data
}

export async function list (page, user) {
  const result = await requests.get(`${URLv2}/filter/${user}/${page}`, config.configWithToken())
  return result.data
}

export async function listAll (page) {
  const result = await requests.get(`${URLv2}/feed/${page}`, config.configWithToken())
  return result.data
}

export default {
  create,
  list,
  listAll
}
