import React from 'react'
import FileBase64 from 'react-file-base64'
import API from '../../../api/index'
import { getItem } from '../../../api/utils'
import ProfileBlank from '../../../static/img/img_perfil_blank.png'
import './Create.css'

const Create = (props) => {
  const image = getItem('image') ? getItem('image') : ProfileBlank
  const [content, setContent] = React.useState('')
  const [disabled, setDisabled] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [files, setFiles] = React.useState([])
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [textSubmit, setTextSubmit] = React.useState('Publicar')

  async function onSubmit () {
    if (!loading) {
      setTextSubmit('Publicando contenido...')
      setLoading(true)
      const response = await API.posts.create({
        content: content,
        files: files
      })
      if (response.ok) {
        setSuccess('Estamos subiendo el contenido, en unos minutos estará disponible')
        setFiles([])
        setContent('')
      }
      setTextSubmit('Publicar')
      setLoading(false)
    }
  }

  function handlerChange (value) {
    if (value) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
    setContent(value)
  }

  function deleteFile (index) {
    files.splice(files.indexOf(index), 1)
    setFiles(files)
  }

  function getFiles (file) {
    setError('')
    setSuccess('')
    const typeFile = file.type.split('/')[0]
    let size = file.size
    size = parseInt(size.replace(' kB', ''))
    if (typeFile === 'image' || typeFile === 'video') {
      if (size < 15360) {
        const data = {
          name_file: file.name,
          format: typeFile,
          base64: file.base64
        }
        setFiles([...files, data])
      } else {
        setError('El archivo es muy pesado, debe ser menor de 10 mb')
      }
    } else {
      setError('No es formato válido, puedes subir fotos o vídeos')
    }
  }

  return (
    <div>
      <div className='new-post-element new-post-element-mobile'>
        <div className='ng-untouched ng-pristine ng-valid'>
          <div>
            <ul className='list-inline' />
          </div>
          <div className='container-avatar-textarea'>
            <img src={image} className='icon-avatar' />
            <textarea
              className='new-post-text ng-untouched ng-pristine ng-valid'
              placeholder='¡Cuéntanos qué sucede!'
              name='content'
              rows='3'
              value={content}
              onChange={(event) => handlerChange(event.target.value)}
            />
          </div>
          <br />
          <hr className='new-post-separator mt-0' />
          <div className='d-flex'>
            <div className='new-post-attachments mr-auto' style={{ width: '100%' }}>
              <div className='clearfix'>
                <FileBase64 onDone={(file) => getFiles(file)} />
                <br /><br />
                <div className='row'>
                  {files.map((file, index) => {
                    if (file.format === 'image') {
                      return (
                        <div key={index} className='col-3'>
                          <img alt='preview' style={{ width: '100%' }} src={file.base64} />
                          <h6 className='new-post-preview' onClick={() => deleteFile(file)}>Eliminar imagen</h6>
                        </div>
                      )
                    } else {
                      return (
                        <div key={index} className='col-3'>
                          <video src={file.base64} controls width='100%' />
                          <h6 className='new-post-preview' onClick={() => deleteFile(file)}>Eliminar video</h6>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          {success && (<span className='badge badge-success'>{success}</span>)}
          {error && (<span className='badge badge-pill badge-danger'>{error}</span>)}
          <div className='d-flex pt-2'>
            <div className='new-post-actions ml-auto'>
              <button className='btn btn-success' onClick={() => onSubmit()} disabled={disabled}>{textSubmit}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Create
