import requests from 'axios'
import config from './config'

const URL = `${config.URL}/visits`

export async function create (user) {
  const result = await requests.post(`${URL}`, {user}, config.configWithToken())
  return result.data
}

export async function count (user) {
  const result = await requests.get(`${URL}/details/${user}`, config.configWithToken())
  return result.data
}

export default {
  create,
  count
}
